import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Header, Button } from "semantic-ui-react";
import Modal from "../components/Modal";
import styled from "styled-components";
import Layout from "../components/Layout";
import ToolkitForm, { IToolkitFormState } from "../components/ToolkitForm";
import { MainHeader } from "../helpers/styledComponents";
import { useUserState } from "../components/Layout";

const PageContainer = styled(Container)`
  .instructions{
    color: #FAB60B;
  }
`;

const SuccessMessageContainer = styled.div<{light?: boolean}>`
  text-align: center;
  .ui.header{
    color: ${props => props.light ? "#fff": "#FAB60B"};
    &.thankYou{
      font-size: 3em;
    }
    &.instruction{
      font-size: 1.5em;
      margin-bottom: 1em;
    }
    @media(min-width: 768px){
      &.thankYou{
        font-size: 5em;
      }
      &.instruction{
        font-size: 2.5em;
      } 
    }
  }
  .downloadBtn{
    color:  ${props => props.light ? "#FAB60B": "#fff"};
    background: ${props => props.light ? "#fff": "#FAB60B"};
  }
`;

const SuccessMessage = ({ light }: {light?: boolean})=>(
  <SuccessMessageContainer light={light}>
    <Header as="h1" className="thankYou"> THANK YOU </Header>
    <Header as="h2" className="instruction">
      DOWNLOAD <br />
      THE FULL STORY INITIATIVE TOOLKIT BELOW
    </Header>
    <Button 
      className="downloadBtn" 
      size="massive" 
      content="DOWNLOAD"
      href="/Full_Story_Toolkit.pdf" target="_blank" />
  </SuccessMessageContainer>
)

const UserFormContainer : React.FC <{setOpenModal : (open: boolean) => void}> = ({setOpenModal} ) => {
  const user = useUserState();

  const handleSubscribe = (data: IToolkitFormState) => {   
    setOpenModal(true);
  }

  return (
    <>   
    {!user.email && (
      <>
        <ToolkitForm onSubmitHandler={handleSubscribe} />
        <Header as="h2" textAlign="center" className="instructions">
          CLICK SUBMIT TO DOWNLOAD <br /> THE FULL STORY INITIATIVE TOOLKIT
        </Header>
      </>
    )}

    {user.email && (
      <SuccessMessage />
    )}
    </>
  );
}


export default () => {
  const [openModal, setOpenModal] = useState(false);
  
  return (
    <>
      <Helmet>
        <title>Toolkit | Full Story Initiative </title>
        <meta name="title" content="Toolkit | Full Story Initiative" />
        <meta name="og:title" content="Toolkit | Full Story Initiative" />
        <meta name="twitter:title" content="Toolkit | Full Story Initiative" />
        <meta name="og:site_name" content="Toolkit | Full Story Initiative" />
      </Helmet> 
      <Layout>
        <PageContainer>
          <MainHeader>
            <Header className="h3 dark normal extraSpace">
              THIS TOOLKIT <br/>
              SERVES AS A GUIDE <br/>
              FOR CONTENT CREATORS
            </Header>
            <Header className="compact toolkitHeader">
              INITIATIVE <br/>
              TOOLKIT
            </Header>
            <Header className="h3 dark">
              Created in partnership with the Full Story Initiative Non-Profit Advisory Council, this toolkit provides guidance to content creators looking to accurately and authentically portray diverse communities, perspectives, and social issues in their work.
            </Header>
          </MainHeader>
          <UserFormContainer setOpenModal={setOpenModal}  />
        </PageContainer>

        <Modal open={openModal} onClose={()=> setOpenModal(false)}>
          <SuccessMessage light />
        </Modal>

      </Layout>
    </>
  )
};
